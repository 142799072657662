* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-width: 1024px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Montserrat";
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#addspan {
  color: 'blue';
  background-color: 'blue';
}

.MuiDropzonePreviewList-removeButton {
  top: 32px !important;
  right: 32px !important;
}

.MuiDropzoneArea-textContainer {
  margin-bottom: 20px;
}
